<template>
  <v-list-item>
    <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
      <slot name="icon">
        <v-icon>fa fa-clock-o</v-icon>
      </slot>
    </v-list-item-icon>
    <v-list-item-content>
      <slot name="content" :status="status">
        <span
          class="grey--text text--darken-2"
          v-html="title"
        ></span>
        <v-chip outlined small class="notification-status-label">{{ status }}</v-chip>
      </slot>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: ['title'],
  computed: {
    status () {
      return this.$t('notification.request.actionStatus.inProgress')
    }
  }
}
</script>
