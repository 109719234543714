<template>
  <Notification
    :value="value"
    v-slot="{ retry, remove, cancel }"
    v-on="$listeners"
  >
    <template v-if="isNew || isInProgress">
      <progress-notification :title="title">
        <template v-slot:content="{ status }">
          <div>
            <div
              class="grey--text text--darken-2"
              v-html="title"
            ></div>
            <v-chip
              class="mt-2"
              small
              outlined
            >{{ status }}</v-chip>
          </div>
          <div class="mt-2">
            <v-btn
              color="success"
              dark
              @click="remove"
            >{{ $t('notification.cancel')}}</v-btn>
          </div>
        </template>
      </progress-notification>
    </template>
    <template v-else-if="isDone">
      <done-notification :title="title">
        <template v-slot:content="{ status }">
          <div>
            <div
              class="grey--text text--darken-2"
              v-html="title"
            ></div>
            <v-chip
              class="mt-2"
              small
              outlined
            >{{ status }}</v-chip>
          </div>
          <div class="mt-2">
            <v-btn
              color="success"
              :href="downloadUrl"
              dark
              @click="remove"
            >{{ $t('notification.download')}}</v-btn>
          </div>
        </template>
      </done-notification>
    </template>
    <template v-else-if="isFail">
      <fail-notification
        :title="title"
        :errorMessage="value.errorMessage"
      >
        <template v-slot:content="{ status }">
          <div>
            <p
              class="grey--text text--darken-2"
              v-html="title"
            ></p>
            <v-alert
              class="notification-error"
              v-if="value.errorMessage"
              color="error"
              border="top"
              outlined
            >
              {{ value.errorMessage }}
            </v-alert>
            <v-chip
              outlined
              small
              class="notification-status-label"
            >{{ status }}</v-chip>
          </div>
          <div class="mt-2">
            <v-btn
              dark
              depressed
              color="success"
              @click="retry"
            >{{ $t('notification.repeat') }}</v-btn>
            <v-btn
              text
              class="ml-2"
              outlined
              color="error"
              @click="remove"
            >{{ $t('notification.cancel') }}</v-btn>
          </div>
        </template>
      </fail-notification>
    </template>
  </Notification>
</template>

<script>
import Notification from './Notification.js'
import DoneNotification from './DoneNotification'
import FailNotification from './FailNotification'
import ProgressNotification from './ProgressNotification'

export default {
  name: 'CdrStatisticNotification',
  props: ['value', 'locale', 'privilegesLocales'],
  components: {
    Notification,
    DoneNotification,
    FailNotification,
    ProgressNotification
  },
  computed: {
    downloadUrl () {
      return `/api/cdr-export-service/${this.value.fileUri}`
    },
    title () {
      return this.$t('notification.request.action.download')
    },
    isNotRequested () {
      return this.value.status === 'NOT_REQUESTED'
    },
    isNew () {
      return this.value.status === 'NEW'
    },
    isInProgress () {
      return this.value.status === 'IN_PROGRESS'
    },
    isFail () {
      return this.value.status === 'FAIL'
    },
    isDone () {
      return this.value.status === 'DONE'
    }
  }
}
</script>
