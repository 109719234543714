<template>
  <Notification :value="value" v-slot="{ remove }">
    <v-list-item>
      <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
        <slot name="icon">
          <v-icon color="success">fa fa-check</v-icon>
        </slot>
      </v-list-item-icon>
      <v-list-item-content>
        <div>
          <span
            class="grey--text text--darken-2"
            v-html="title"
          ></span>
        </div>
        <div class="mt-2">
          <v-btn
            color="green"
            dark
            depressed
            @click="remove"
          >OK</v-btn>
        </div>
      </v-list-item-content>
    </v-list-item>
  </Notification>
</template>

<script>
import Notification from './Notification'
export default {
  name: 'ExtensionNotification',
  props: ['value', 'locale', 'privilegesLocales'],
  components: {
    Notification
  },
  computed: {
    title () {
      let name = this.value.itemName
      name = name.charAt(0).toUpperCase() + name.slice(1)
      let title = this.$t('notification.request.action.subscriberServiceUpdate', { name })
      return title
    }
  }
}
</script>
