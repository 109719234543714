export default [
  {
    action: 'crmUserSyncCallback',
    type: 'CRM_USER_SYNC_CALLBACK',
    billingRequestStatus: 'DONE',
    errorCode: '400',
    errorDetails: 'some error text',
    id: '060c3f02-570a-960f-4ee5-7315891583f2',
    internalNumber: '5555',
    userName: 'WPamzhachih1'
  },
  {
    action: 'crmUserSyncCallback',
    type: 'CRM_USER_SYNC_CALLBACK',
    billingRequestStatus: 'FAIL',
    errorCode: '400',
    errorDetails: 'some error text',
    id: '060c3f02-570a-960f-4ee5-7315891583f3',
    internalNumber: '5555',
    userName: 'WPamzhachih1'
  },
  {
    id: 77,
    itemName: 'AddSubscriber',
    requestid: '01600af4-9efa-4c23-aab7-a06bac55d81c',
    status: 'IN_PROGRESS',
    type: 'EXTENSION_ADD'
  },
  {
    id: 78,
    itemName: 'Не беспокоить',
    requestid: '01600af4-9efa-4c23-aab7-a06bac55d81e',
    status: 'DONE',
    type: 'SUBSCRIBER_SERVICE_UPDATE'
  },
  {
    id: 79,
    itemName: 'EnableAlarm',
    requestid: '01600af4-9efa-4c23-aab7-a06bac55d81e',
    status: 'FAIL',
    errorMessage: 'some looooooooooooooooooooooooooooooooooooong error occured',
    type: 'EXTENSION_ADD'
  },
  {
    id: 103,
    action: 'updateAnalyticExportJob',
    type: 'CDR_STATISTIC_FILE_DOWNLOAD',
    status: 'FAIL',
    begin: '2020-01-01 00:00:00.0',
    end: '2020-12-31 23:59:59.0',
    errorMessage: 'some looooooooooooooooooooooooooooooooooooong error occured',
    updated: {
      month: 'NOVEMBER',
      dayOfWeek: 'FRIDAY',
      dayOfYear: 325,
      year: 2020,
      monthValue: 11,
      dayOfMonth: 20,
      hour: 18,
      minute: 58,
      second: 44,
      nano: 0,
      chronology: { calendarType: 'iso8601', id: 'ISO' }
    },
    pbxId: 'WPamzhachih1'
  }, {

  }
]
