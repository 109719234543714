<template>
  <v-list-item :class="{ 'grey lighten-3': hover }">
    <v-list-item-content>
      <v-list-item-title :title="item.title">
        {{ item.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        :class="costClass"
      >
        {{ $t('extension.price') }}: {{ item.cost }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ $t('extension.quantity') }}: {{ item.count }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="canChange">
      <v-btn
        icon
        @click="$emit('remove', item)"
      >
        <v-icon
          v-show="hover"
          small
          color="error"
        >fa-times</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  props: {
    canChange: {
      type: Boolean,
      default: false
    },
    costClass: {
      type: String,
      default: null
    },
    hover: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    }
  }
}
</script>
