<template>
  <v-app :id="dataId">
    <v-list
      v-if="items && items.length"
      class="py-0 elevation-2 mb-5">
      <template v-if="listTitle">
        <v-subheader class="subtitle-1"> {{ listTitle }} </v-subheader>
        <v-divider />
      </template>
      <div
        style="max-height: 300px"
        class="overflow-y-auto"
      >
        <template v-for="(item, i) in items">
          <v-hover
            v-slot:default="{ hover }"
            :key="i"
          >
            <component
              :canChange="canChange"
              :costClass="costClass"
              :is="getItemComponentName(item)"
              :item="item"
              :hover="hover"
              @add="$emit('add', $event)"
              @remove="$emit('remove', $event)"
            ></component>
          </v-hover>
        </template>
      </div>
    </v-list>
  </v-app>
</template>
<script>
import MultipleExtensionItem from './components/MultipleExtensionItem'
import RatePlanExtensionItem from './components/RatePlanExtensionItem'
import SingleExtensionItem from './components/SingleExtensionItem'
import InitI18nLocale from '../mixins/InitI18nLocale'

export default {
  components: {
    MultipleExtensionItem,
    RatePlanExtensionItem,
    SingleExtensionItem
  },
  mixins: [InitI18nLocale],
  props: {
    canChange: {
      type: Boolean,
      default: true
    },
    dataId: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: null
    },
    listTitle: {
      type: String,
      default: null
    },
    costClass: {
      type: String,
      default: null
    }
  },
  methods: {
    getItemComponentName (item) {
      if (item.multiple) {
        return 'multiple-extension-item'
      }
      if (item.id === 'rate-plan') {
        return 'rate-plan-extension-item'
      }
      return 'single-extension-item'
    }
  }
}
</script>
