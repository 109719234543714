<template>
  <Notification
    :value="value"
    v-slot="{ remove }"
    v-on="$listeners"
  >
    <template v-if="value.billingRequestStatus === 'DONE'">
      <done-notification>
        <template v-slot:content="{ status }">
          <p
            class="grey--text text--darken-2"
            v-html="successTitle"
          ></p>
          <div class="mt-2">
            <v-btn
              class="btn-success"
              dark
              depressed
              @click="remove"
            >OK</v-btn>
          </div>
        </template>
      </done-notification>
    </template>
    <template v-else-if="value.billingRequestStatus === 'FAIL'">
      <fail-notification>
        <template v-slot:content>
          <div>
            <p
              class="grey--text text--darken-2"
              v-html="errorTitle"
            ></p>
            <v-alert
              class="notification-error"
              v-if="errorMessage"
              color="error"
              border="top"
              outlined
            >
              {{ errorMessage }}
            </v-alert>
          </div>
          <div class="mt-2">
            <v-btn
              dark
              depressed
              color="error"
              @click="remove"
            >OK</v-btn>
          </div>
        </template>
      </fail-notification>
    </template>
  </Notification>
</template>

<script>
import Notification from './Notification'
import FailNotification from './FailNotification'
import DoneNotification from './DoneNotification'
export default {
  name: 'ExtensionNotification',
  props: ['value', 'locale', 'privilegesLocales'],
  components: {
    Notification,
    FailNotification,
    DoneNotification
  },
  computed: {
    errorMessage () {
      return this.value.errorDetails
    },
    successTitle () {
      return this.$t('notification.crm-sync-success', { name: this.value.userName })
    },
    errorTitle () {
      return this.$t('notification.crm-sync-fail', { name: this.value.userName })
    }
  }
}
</script>
