import vueCustomElement from 'vue-custom-element'
import Vue from 'vue'
import vuetify from '../plugins/vuetify'
import i18n from '../plugins/i18n'

const App = require('./App').default

Vue.config.productionTip = false

Vue.use(vueCustomElement)
App.vuetify = vuetify
App.i18n = i18n

Vue.customElement('wc-extension-list', App, {
  shadow: false
})
