export default {
  props: {
    dataId: {
      type: String,
      default: null
    }
  },
  computed: {
    attachId () {
      return `#${this.dataId} > .v-application--wrap`
    }
  }
}
