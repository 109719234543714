
const setAppLocale = ($i18n, locale) => $i18n && locale && ($i18n.locale = locale)

export default {
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  created () {
    setAppLocale(this.$i18n, this.locale)
  },
  watch: {
    locale (v) {
      setAppLocale(this.$i18n, v)
    }
  }
}
