<template>
  <Notification
    :value="value"
    v-slot="{ retry, remove, cancel }"
    v-on="$listeners"
  >
    <template v-if="isNew || isInProgress">
      <progress-notification :title="title" />
    </template>
    <template v-else-if="isDone">
      <done-notification
        :title="title"
        @remove="remove"
      />
    </template>
    <template v-else-if="isFail">
      <fail-notification
        :title="title"
        :errorMessage="value.errorMessage"
        @remove="remove"
        @cancel="cancel"
      />
    </template>
  </Notification>
</template>

<script>
import Notification from './Notification.js'
import DoneNotification from './DoneNotification'
import FailNotification from './FailNotification'
import ProgressNotification from './ProgressNotification'

const localizationKeys = {
  'EXTENSION_ADD': 'extensionAdd',
  'EXTENSION_REMOVE': 'extensionRemove',
  'EXTERNAL_NUMBER_ADD': 'numberAdd',
  'EXTERNAL_NUMBER_REMOVE': 'numberRemove',
  'RATE_PLAN_CHANGE': 'changeRate'
}

export default {
  name: 'ExtensionNotification',
  props: ['value', 'locale', 'privilegesLocales'],
  components: {
    Notification,
    DoneNotification,
    FailNotification,
    ProgressNotification
  },
  computed: {
    title () {
      let name = this.privilegesLocales[this.locale][this.value.itemName] || this.value.itemName
      name = name.charAt(0).toUpperCase() + name.slice(1)
      let title = this.$t(`notification.request.action.${localizationKeys[this.value.type]}`, { name })
      return title
    },
    isNew () {
      return this.value.status === 'NEW'
    },
    isInProgress () {
      return this.value.status === 'IN_PROGRESS'
    },
    isFail () {
      return this.value.status === 'FAIL'
    },
    isDone () {
      return this.value.status === 'DONE'
    }
  }
}
</script>
