<template>
  <v-list-item>
    <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
      <slot name="icon">
        <v-icon color="error">fa fa-times-circle</v-icon>
      </slot>
    </v-list-item-icon>
    <v-list-item-content>
      <slot name="content" :status="status">
        <div>
          <p
            class="grey--text text--darken-2"
            v-html="title"
          ></p>
          <v-alert
            class="notification-error"
            v-if="errorMessage"
            color="error"
            border="top"
            outlined
          >
            {{ errorMessage }}
          </v-alert>
          <v-chip outlined small class="notification-status-label">{{ status }}</v-chip>
        </div>
        <div class="mt-2">
          <v-btn
            dark
            depressed
            color="error"
            @click="$emit('remove')"
          >OK</v-btn>
        </div>
      </slot>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: ['title', 'errorMessage'],
  computed: {
    status () {
      return this.$t('notification.request.actionStatus.fail')
    }
  }
}
</script>
