<template>
  <v-list-item>
    <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
      <slot name="icon">
        <v-icon color="success">fa fa-check</v-icon>
      </slot>
    </v-list-item-icon>
    <v-list-item-content>
      <slot name="content" :status="status">
        <div>
          <span
            class="grey--text text--darken-2"
            v-html="title"
          ></span>
          <v-chip
            class="notification-status-label"
            small
            outlined
          >{{ status }}</v-chip>
        </div>
        <div class="mt-2">
          <v-btn
            class="btn-success"
            dark
            depressed
            @click="$emit('remove')"
          >OK</v-btn>
        </div>
      </slot>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: ['title'],
  computed: {
    status () {
      return this.$t('notification.request.actionStatus.done')
    }
  }
}
</script>
