var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Notification',_vm._g({attrs:{"value":_vm.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var retry = ref.retry;
var remove = ref.remove;
var cancel = ref.cancel;
return [(_vm.isNew || _vm.isInProgress)?[_c('progress-notification',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var status = ref.status;
return [_c('div',[_c('div',{staticClass:"grey--text text--darken-2",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('v-chip',{staticClass:"mt-2",attrs:{"small":"","outlined":""}},[_vm._v(_vm._s(status))])],1),_c('div',{staticClass:"mt-2"},[_c('v-btn',{attrs:{"color":"success","dark":""},on:{"click":remove}},[_vm._v(_vm._s(_vm.$t('notification.cancel')))])],1)]}}],null,true)})]:(_vm.isDone)?[_c('done-notification',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var status = ref.status;
return [_c('div',[_c('div',{staticClass:"grey--text text--darken-2",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('v-chip',{staticClass:"mt-2",attrs:{"small":"","outlined":""}},[_vm._v(_vm._s(status))])],1),_c('div',{staticClass:"mt-2"},[_c('v-btn',{attrs:{"color":"success","href":_vm.downloadUrl,"dark":""},on:{"click":remove}},[_vm._v(_vm._s(_vm.$t('notification.download')))])],1)]}}])})]:(_vm.isFail)?[_c('fail-notification',{attrs:{"title":_vm.title,"errorMessage":_vm.value.errorMessage},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var status = ref.status;
return [_c('div',[_c('p',{staticClass:"grey--text text--darken-2",domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.value.errorMessage)?_c('v-alert',{staticClass:"notification-error",attrs:{"color":"error","border":"top","outlined":""}},[_vm._v(" "+_vm._s(_vm.value.errorMessage)+" ")]):_vm._e(),_c('v-chip',{staticClass:"notification-status-label",attrs:{"outlined":"","small":""}},[_vm._v(_vm._s(status))])],1),_c('div',{staticClass:"mt-2"},[_c('v-btn',{attrs:{"dark":"","depressed":"","color":"success"},on:{"click":retry}},[_vm._v(_vm._s(_vm.$t('notification.repeat')))]),_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","outlined":"","color":"error"},on:{"click":remove}},[_vm._v(_vm._s(_vm.$t('notification.cancel')))])],1)]}}])})]:_vm._e()]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }