<template>
  <v-app :id="dataId" class="wc-root">
    <v-combobox
      ref="combobox"
      v-bind="{ ...$props }"
      dense
      outlined
      clearable
      multiple
      small-chips
      @change="onChange"
      :attach="`#${dataId} > .v-application--wrap`"
      chips
    >
      <template
        v-if="maxItems"
        v-slot:selection="{ item, index }"
      >
        <v-chip v-if="index < maxItems" small>
            <span>{{ item[itemText] }}</span>
        </v-chip>
        <span v-if="index === maxItems" class="grey--text caption">
          (+{{ value.length - maxItems }} {{othersText}})
        </span>
      </template>
      <template v-slot:no-data="">
        <v-list-item v-if="noDataText">
          <v-list-item-content>
            <v-list-item-title>
              {{ noDataText }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </v-app>
</template>

<script>
import VTextFieldStyleProps from '../mixins/VTextFieldStyleProps'
import { VCombobox } from 'vuetify/lib'

export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    dataId: {
      type: String,
      default: null
    },
    value: {
      type: Array,
      default () {
        return []
      }
    },
    items: {
      type: Array,
      default: () => [
        { label: 'Test111', value: 1111 },
        { label: 'Test112', value: 1112 },
        { label: 'Test113', value: 1113 },
        { label: 'Test114', value: 1114 },
        { label: 'Test115', value: 1115 }
      ]
    },
    label: {
      type: String,
      default: ''
    },
    noDataText: {
      type: String,
      default: ''
    },
    returnObject: {
      type: Boolean,
      default: true
    },
    itemText: {
      type: String,
      default: null
    },
    itemValue: {
      type: String,
      default: null
    },
    maxItems: {
      type: Number,
      default: null
    },
    othersText: {
      type: String,
      default: 'other'
    }
  },
  mixins: [VTextFieldStyleProps],
  name: 'app',
  components: {
    VCombobox
  },
  methods: {
    onChange (e) {
      this.$emit('select', e)
    }
  }
}
</script>
<style>
.wc-root.v-application.theme--light .v-application--wrap {
  min-height: 0;
}
.wc-root.v-application.theme--light {
  position: relative;
  background: #fff;
}
</style>
