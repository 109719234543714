<template>
  <v-combobox
    v-bind="{ ...$attrs }"
    small-chips
    @change="onChange"
  >
    <template v-slot:no-data="">
      <v-list-item v-if="noDataText">
        <v-list-item-content>
          <v-list-item-title>
            {{ noDataText }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import { VCombobox } from 'vuetify/lib'

export default {
  computed: {
    noDataText () {
      return this.$attrs.noDataText
    }
  },
  components: {
    VCombobox
  },
  methods: {
    onChange (e) {
      this.$emit('change', e)
    }
  }
}
</script>
