<template>
  <v-app
    :id="dataId"
    class="wc-root"
    style="background: transparent"
  >
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :close-on-click="false"
      :attach="attachId"
      :min-width="minWidth"
      max-height="320px"
      left
      offset-y
      nudge-bottom="20px"
      dot
      :nudge-right="nudgeRight"
    >
      <template v-slot:activator="{ on }">
        <v-badge
          class="notificaion-badge"
          color="red"
          overlap
          :content="badgeContent"
          top
        >
          <v-btn
            class="icon"
            icon
            v-on="on"
          >
            <v-icon
              color="grey"
            >fa-bell-o</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <div>
        <v-list>
          <v-list>
            <v-list-item dense>
              <v-list-item-content>
                <span class="grey--text text--darken-4">
                  {{ $t('notification.notifications') }} ({{ items.length }})
                </span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <template v-for="(notification, idx) in items">
            <div :key="notification.id">
              <component
                :is="getNotificationComponent(notification.type)"
                :value="notification"
                :locale="locale"
                :privilegesLocales="privilegesLocales"
                @remove="$emit('remove', $event)"
                @cancel="$emit('cancel', $event)"
                @retry="$emit('retry', $event)"
              ></component>
              <v-divider v-if="idx < items.length - 1"></v-divider>
            </div>
          </template>
        </v-list>
      </div>
    </v-menu>
  </v-app>

</template>

<script>
import Vue from 'vue'
import withDataId from '../mixins/VuetifyAppMixin'
import withI18n from '../mixins/InitI18nLocale'
import notifications from './notifications'
import BillingNotification from './BillingNotification'
import CdrStatisticNotification from './CdrStatisticNotification'
import SubscriberServiceUpdateNotification from './SubscriberServiceUpdateNotification'
import CrmCallbackNotification from './CrmCallbackNotification'
import privilegesLocale from './privileges-data.json'

export default {
  mixins: [withDataId, withI18n],
  props: {
    items: {
      type: Array,
      default () {
        return notifications
      }
    },
    privilegesLocales: {
      type: Object,
      default () {
        return privilegesLocale
      }
    }
  },
  watch: {
    items: {
      deep: true,
      async handler (v) {
        if (this.$vuetify.breakpoint.mdAndUp) {
          await this.$nextTick()
          this.menu = v && v.length > 0
        }
      }
    }
  },
  data () {
    return {
      menu: false
    }
  },
  methods: {
    getNotificationComponent (type) {
      switch (type) {
        case 'CDR_STATISTIC_FILE_DOWNLOAD': return CdrStatisticNotification
        case 'SUBSCRIBER_SERVICE_UPDATE': return SubscriberServiceUpdateNotification
        case 'CRM_USER_SYNC_CALLBACK': return CrmCallbackNotification
        default: return BillingNotification
      }
    }
  },
  computed: {
    minWidth () {
      if (this.$vuetify.breakpoint.lgAndUp) return '400px'
      return '300px'
    },
    nudgeRight () {
      if (this.$vuetify.breakpoint.lgAndUp) return 0
      return '75px'
    },
    badgeContent () {
      if (this.$vuetify.breakpoint.lgAndUp) return this.items.length
      return null
    }
  }
}
</script>
<style>
.wc-root.v-application.theme--light .v-application--wrap {
  min-height: 0;
}
.wc-root.v-application.theme--light {
  position: relative;
  background: #fff;
}
.notificaion-badge .v-badge__badge {
  min-width: auto !important;
  height: auto !important;
}
@media (max-width: 1024px) {
  .notificaion-badge .v-badge__badge {
    padding-right: 2px !important;
  }
}
</style>
