<template>
  <v-app :id="dataId" class="wc-root">
    <wc-combobox
      v-bind="{ ...$props }"
      :attach="`#${dataId} > .v-application--wrap`"
      chips
      @change="onChange"
    />
  </v-app>
</template>

<script>
import WcCombobox from './components/WcCombobox'
import VTextFieldStyleProps from '../mixins/VTextFieldStyleProps'

export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    dataId: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    hideSelected: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    noDataText: {
      type: String,
      default: ''
    },
    returnObject: {
      type: Boolean,
      default: true
    }
  },
  mixins: [
    VTextFieldStyleProps
  ],
  name: 'app',
  components: {
    WcCombobox
  },
  methods: {
    onChange (e) {
      let value = e
      if (typeof value === 'string') {
        value = { text: e }
      }
      this.$emit('select', value)
    }
  }
}
</script>
<style>
  .wc-root.v-application.theme--light .v-application--wrap {
    min-height: 0;
  }
  .wc-root.v-application.theme--light {
    position: relative;
    background: #fff;
  }
</style>
