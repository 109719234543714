import vueCustomElement from 'vue-custom-element'
import Vue from 'vue'

const App = require('./App').default
const useVuetify = require('../plugins/vuetify.js').useVuetify

Vue.config.productionTip = false

Vue.use(vueCustomElement)
App.vuetify = useVuetify({ icons: 'md' })

Vue.customElement('wc-combobox-filter', App, {
  shadow: false
})
