<template>
  <v-list-item :class="{ 'grey lighten-3': hover }">
    <v-list-item-action class="mr-3">
      <v-icon
        v-if="isItemActive(item)"
        small
        color="success"
      >fa-check</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title :title="item.title">
        {{ item.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        :class="costClass"
        v-if="item.cost"
      >
        {{ $t('extension.price') }}: {{ item.cost }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="canChange">
      <v-btn
        icon
        @click="onClick(item)"
      >
        <span v-show="hover">
          <template v-if="isItemActive(item)">
            <v-icon
              small
              color="error"
            >fa-times</v-icon>
          </template>
          <template v-else>
            <v-icon
              small
              color="success"
            >fa-plus</v-icon>
          </template>
        </span>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  props: {
    canChange: {
      type: Boolean,
      default: false
    },
    costClass: {
      type: String,
      default: null
    },
    hover: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    }
  },
  methods: {
    isItemActive (item) {
      return item.id
    },
    onClick (item) {
      const action = this.isItemActive(item) ? 'remove' : 'add'
      this.$emit(action, item)
    }
  }
}
</script>
