import vueCustomElement from 'vue-custom-element'
import Vue from 'vue'

import App from './App.vue'
import Vuetify from '../plugins/vuetify'
import i18n from '../plugins/i18n'
import './styles.css'

Vue.config.productionTip = false

Vue.use(vueCustomElement)
App.vuetify = Vuetify
App.i18n = i18n

Vue.customElement('wc-notifications', App, {
  shadow: false
})
