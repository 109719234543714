<template>
  <v-app :id="dataId">
    <v-content
      class="fill-height"
      fluid>
      <v-container>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-2">
              <v-card-title>
                {{ $t(`error.${status}.title`) }}
              </v-card-title>
              <v-card-text>
                {{ $t(`error.${status}.description`) }}
              </v-card-text>
              <v-card-actions>
                <v-btn text color="primary" @click="$emit('logout')">
                  {{ $t('error.logoutButton') }}
                </v-btn>
                <v-spacer />
                <v-btn text color="primary" @click="$emit('retry')">
                  {{ $t('error.retryButton') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
import InitI18nLocale from '../mixins/InitI18nLocale'

export default {
  mixins: [InitI18nLocale],
  props: {
    dataId: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: null
    }
  }
}
</script>
<style>
.v-application {
  height: 100%;
}
.v-application.theme--light {
  position: relative;
  background: transparent;
}
</style>
