// import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 480,
      sm: 768,
      md: 1042,
      lg: 1500
    }
  },
  icons: {
    iconfont: 'fa4'
  },
  theme: {
    // disable: true
  }
})

export function useVuetify (options) {
  return new Vuetify({
    breakpoint: {
      thresholds: {
        xs: 480,
        sm: 768,
        md: 1042,
        lg: 1500
      }
    },
    icons: {
      iconfont: options.icons || 'fa4'
    },
    theme: {
      // disable: true
    }
  })
}
