
export default {
  props: ['value'],
  methods: {
    emit (event) {
      this.$emit(event, this.value)
    }
  },
  render () {
    return this.$scopedSlots.default({
      cancel: () => this.emit('cancel'),
      retry: () => this.emit('retry'),
      remove: () => this.emit('remove')
    })
  }
}
