<template>
  <v-list-item :class="{ 'grey lighten-3': hover }">
    <v-list-item-action class="mr-3">
      <v-icon
        small
        color="success"
      >fa-check</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title :title="item.title">
        {{ item.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ $t('extension.includedInRatePlan') }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  props: {
    hover: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    }
  }
}
</script>
