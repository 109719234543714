import vueCustomElement from 'vue-custom-element'
import Vue from 'vue'

const App = require('./App').default
const vuetify = require('../plugins/vuetify.js').default

Vue.config.productionTip = false

Vue.use(vueCustomElement)
App.vuetify = vuetify

Vue.customElement('wc-combobox', App, {
  shadow: false
})
